/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/elements/Hero'

// Third Party
import parse from 'html-react-parser'
import styled, { css } from 'styled-components'

const Terms = styled.div`
  h2 {
    ${props => css`
      font-size: ${props.theme.font.size.xm};
      font-weight: ${props.theme.font.weight.xl};
      padding-top: 20px;
    `}
  }

  h3 {
    padding-top: 20px;
  }
`

export const Query = graphql`
  query($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      pageVoorwaarden {
        voorwaardenbanner {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality:100)
              }
            }
          }
          title
        }
        voorwaardeninfo {
          text
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

const TermsTemplate = ({
  data: {
    page: {
      seo,
      uri,
      pageVoorwaarden,
    },
  },
}) => {
  return (
    <Layout>
      {seo && (
        <SEO seo={seo} />
      )}
      <Hero src={pageVoorwaarden.voorwaardenbanner.image} title={pageVoorwaarden.voorwaardenbanner.title} />
      <Terms className="container pb-5">
        {parse(pageVoorwaarden.voorwaardeninfo.text)}
      </Terms>
    </Layout>
  )
}

export default TermsTemplate
